import React from 'react';
// import sections
import Search from '../components/sections/Search';

const Service = () => {

  return (
    <>
      <Search />
    </>
  );
}

export default Service;